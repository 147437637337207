import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "#";
    return (
      <header className="site-header-one go-top">
        <nav className="main-nav__one stricky">
          <div class="container-fluid">
            <div class="main-nav__logo-box">
              <a href="#/">
                <img height={35} width={200} src="logo.png" alt="logo" />
              </a>
              <a href="#" class="side-menu__toggler">
                <i class="fa fa-bars"></i>
              </a>
            </div>
            <div class="main-nav__main-navigation">
              <ul class=" main-nav__navigation-box">
                <li class="dropdown current">
                  <a href="#/">Home</a>
                </li>
                <li>
                  <a href="#/about">About</a>
                </li>
                <li class="dropdown">
                  <a href="#/service">Services</a>
                </li>
              </ul>
            </div>
            <div class="main-nav__right">
              <a class="thm-btn" href="#/contact">
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Navbar;
