import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class WhyChooseUs extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="service-one go-top">
        <div className="container">
          <div className="block-title text-center">
            <p>
              <span>Why Like Us</span>
            </p>
            <h3>
              Success Your Business With <br />
              Establish Agency
            </h3>
            <div className="block-title__line" />
            {/* /.block-title__line */}
          </div>
          {/* /.block-title */}
          <div className="row high-gutter  go-top">
            <div className="col-lg-4">
              <div className="service-one__single">
                <div className="service-one__top">
                  <div className="service-one__icon">
                    <img
                      src={publicUrl + "assets/images/shapes/service-i-1.png"}
                      alt={imagealt}
                    />
                  </div>
                  {/* /.service-one__icon */}
                  <div className="service-one__top-content">
                    <h3>Performance marketing</h3>
                    <p>Improve Business</p>
                  </div>
                  {/* /.service-one__top-content */}
                </div>
                {/* /.service-one__top */}
                <p>
                  Performance marketing focuses on measurable outcomes and
                  results, leveraging data-driven strategies to maximize return
                  on investment.
                </p>
              </div>
              {/* /.service-one__single */}
            </div>
            {/* /.col-lg-4 */}
            <div className="col-lg-4">
              <div className="service-one__single">
                <div className="service-one__top">
                  <div className="service-one__icon">
                    <img
                      src={publicUrl + "assets/images/shapes/service-i-2.png"}
                      alt={imagealt}
                    />
                  </div>
                  {/* /.service-one__icon */}
                  <div className="service-one__top-content">
                    <h3>SEO, PPC &amp; Social Media Soluation</h3>
                    <p>SEO Optimization</p>
                  </div>
                  {/* /.service-one__top-content */}
                </div>
                {/* /.service-one__top */}
                <p>
                  An integrated SEO, PPC, and Social Media solution combines
                  strategies to optimize website visibility, drive targeted
                  traffic, and enhance brand engagement across multiple digital
                  channels, ensuring a comprehensive approach to online
                  marketing success.
                </p>
              </div>
              {/* /.service-one__single */}
            </div>
            {/* /.col-lg-4 */}
            <div className="col-lg-4">
              <div className="service-one__single">
                <div className="service-one__top">
                  <div className="service-one__icon">
                    <img
                      src={publicUrl + "assets/images/shapes/service-i-3.png"}
                      alt={imagealt}
                    />
                  </div>
                  {/* /.service-one__icon */}
                  <div className="service-one__top-content">
                    <h3>Search Engine Marketing (SEM)</h3>
                  </div>
                  {/* /.service-one__top-content */}
                </div>
                {/* /.service-one__top */}
                <p>
                  Search Engine Marketing (SEM) encompasses various strategies
                  aimed at increasing a website's visibility in search engine
                  results pages (SERPs) through paid advertising campaigns, such
                  as pay-per-click (PPC) ads and search engine optimization
                  (SEO) techniques.
                </p>
              </div>
              {/* /.service-one__single */}
            </div>
            {/* /.col-lg-4 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default WhyChooseUs;
