import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutPage extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="service-two service-two__service-page service-two__about-page  go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="service-two__image">
                <img
                  src={
                    publicUrl + "assets/images/resources/about-page-moc-1.png"
                  }
                  alt={imagealt}
                />
              </div>
              {/* /.service-two__image */}
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <div className="service-two__block">
                <div className="block-title-two text-left">
                  <p>about us</p>
                  <h3>
                    Exclusive Agency To <br /> Provide Soluation
                  </h3>
                </div>
                {/* /.block-title-two */}
                <p>
                  Exclusive agency partnerships offer businesses dedicated
                  access to tailored solutions, ensuring specialized attention
                  and expertise in addressing their unique needs. Through such
                  arrangements, companies benefit from customized strategies
                  crafted to optimize performance and maximize results. These
                  partnerships foster a deeper understanding of the client's
                  goals, allowing for precise alignment and ongoing refinement
                  of marketing initiatives. With a focus on exclusivity,
                  agencies commit to delivering unparalleled service and
                  support, building long-term relationships founded on trust and
                  mutual success. This approach enables businesses to gain a
                  competitive edge in their respective industries, leveraging
                  the agency's resources and insights for sustained growth and
                  market leadership.{" "}
                </p>

                <Link to="/service" className="thm-btn">
                  Read More <i className="fa fa-angle-double-right" />
                </Link>
                {/* /.thm-btn */}
              </div>
              {/* /.service-two__block */}
            </div>
            {/* /.col-lg-5 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default AboutPage;
