import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceV5 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    const services = [
      {
        title: "Organic Marketing",
        description:
          "Empower your brand's growth sustainably with our Organic Marketing services, designed to cultivate genuine connections and long-term relationships with your audience. Unlike traditional advertising, Organic Marketing focuses on building brand visibility and credibility through authentic, value-driven content and engagement strategies. Our expert team specializes in crafting compelling content, optimizing your website for search engines, and fostering meaningful interactions across social media platforms. By prioritizing quality over quantity and prioritizing customer-centric communication, we help your brand resonate with your target audience on a deeper level. Partner with us to cultivate a loyal community of brand advocates and achieve lasting success in today's dynamic digital landscape.",
        microServices: [
          {
            title: "Social Media Optimization",
            description:
              "Optimize your brand's social media presence to drive engagement and expand your audience reach effectively.",
          },
          {
            title: "Conversion Rate Optimization",
            description:
              "Maximize your website's potential by converting more visitors into customers through strategic analysis, testing, and optimization of user experience and journey.",
          },
          {
            title: "Product Feed Optimization",
            description:
              "Enhance your e-commerce performance by fine-tuning product data and optimizing feed structures to maximize visibility and drive sales across various shopping platforms.",
          },
          {
            title: "Search Engine Optimization",
            description:
              "Maximize online visibility and traffic through strategic optimization to secure top rankings on search engine results pages.",
          },
        ],
      },
      {
        title: "Paid Search Marketing",
        description:
          "Boost your online presence and drive targeted traffic to your website with our Paid Search Marketing services. Our experienced team specializes in creating and managing highly effective pay-per-click (PPC) campaigns across platforms like Google Ads, Bing Ads, and social media channels. We tailor our strategies to your specific goals and budget, ensuring maximum return on investment (ROI) for every campaign. From keyword research and ad creation to bid management and performance tracking, we handle every aspect of your paid search campaigns with precision and expertise. Partner with us to leverage the power of paid search marketing and reach your target audience with precision and impact.",
        microServices: [
          {
            title: "Google Ads",
            description:
              "Accelerate your online presence and drive targeted traffic with precision through strategic Google Ads campaigns, ensuring maximum ROI and business growth.",
          },
          {
            title: "Native Ads",
            description:
              "Drive seamless brand integration and engagement with native ads, blending seamlessly into content for heightened audience connection and impact.",
          },
          {
            title: "PPC (Pay Per Click)",
            description:
              "Optimize your online advertising expenditure and maximize conversions with PPC (Pay Per Click) campaigns, ensuring targeted visibility and measurable results across digital platforms.",
          },
          {
            title: "Performance Marketing",
            description:
              "Achieve measurable results and maximize ROI with performance marketing strategies, focusing on driving actions that directly impact your business objectives and bottom line.",
          },
          {
            title: "Programatic Advertising",
            description:
              "Streamline ad buying and targeting through automated processes with programmatic advertising, optimizing campaign performance and maximizing ROI across digital channels.",
          },
        ],
      },
      {
        title: "E-Commerce Marketing",
        description:
          "Elevate your e-commerce business with our comprehensive E-Commerce Marketing solutions tailored to drive growth, increase sales, and maximize ROI. Our expert team specializes in crafting customized strategies that leverage the latest digital marketing tactics to effectively reach and engage your target audience. From search engine optimization (SEO) and pay-per-click (PPC) advertising to email marketing, social media management, and content creation, we offer a full spectrum of services to enhance your online presence and drive conversions. Whether you're looking to increase brand awareness, optimize your product listings, or improve customer retention, we're committed to delivering results that exceed your expectations. Partner with us to unlock the full potential of your e-commerce business and achieve sustainable success in today's competitive digital landscape.",
        microServices: [
          {
            title: "Ecommerce PPC",
            description:
              "Drive targeted traffic and increase sales for your online store with precision-targeted Ecommerce PPC campaigns, optimized for maximum conversion and ROI.",
          },
          {
            title: "Ecommerce SEO",
            description:
              "Boost online store visibility and sales through tailored Ecommerce SEO strategies, optimizing product pages and site architecture to rank higher and attract targeted traffic.",
          },
          {
            title: "Ecommerce Social Media",
            description:
              "Harness social media's power to drive ecommerce success, leveraging strategic content and community engagement to boost brand visibility and sales.",
          },
          {
            title: "Product Feed Optimization",
            description:
              "Maximize your e-commerce performance by optimizing product feeds, ensuring accurate and compelling data to enhance visibility and drive sales across various online shopping platforms.",
          },
        ],
      },
    ];

    return (
      <section className="service-two service-two__service-page  go-top">
        <div className="container">
          {services.map((service, idx) => {
            return (
              <div className="row">
                <div className="col-lg-5">
                  <div className="service-two__block">
                    <div className="block-title-two text-left">
                      <h3>{service.title}</h3>
                    </div>
                    {/* /.block-title-two */}
                    <p>{service.description}</p>
                    {/* <ul className="list-unstyled video-one__list">
                      <li>
                        <i className="far fa-check" />
                        Smashing Book 6 Is Here New Frontiers In Web Design
                      </li>
                      <li>
                        <i className="far fa-check" />
                        Introduction To Animation And The iMessage App Store
                        With Shruggie
                      </li>
                      <li>
                        <i className="far fa-check" />
                        Get Your Mobile Site Ready For The 2018 Holiday ways
                        goods
                      </li>
                      <li>
                        <i className="far fa-check" />
                        Making Distributed Product Teams Work More Efficiently
                        other
                      </li>
                    </ul> */}
                    {/* /.list-unstyled video-one__list */}

                    {/* /.thm-btn */}
                  </div>
                  {/* /.service-two__block */}
                </div>
                {/* /.col-lg-5 */}
                <div className="col-lg-7">
                  <div className="service-two__box-wrap">
                    <div className="row">
                      {service.microServices.map((microService, idx) => {
                        return (
                          <div className="col-md-6">
                            <div className="service-two__single js-tilt">
                              <div className="service-two__single-image" />
                              <div className="service-two__single-inner">
                                <i className="fal fa-hand-holding-usd" />
                                <h3>{microService.title}</h3>
                                <p>{microService.description}</p>
                                {/* <Link
                                  to="/service-details"
                                  className="service-two__link"
                                >
                                  <i className="fa fa-long-arrow-alt-right" />
                                </Link> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {/* /.col-md-6 */}
                    </div>
                    {/* /.row */}
                  </div>
                  {/* /.service-two__box-wrap */}
                </div>
                {/* /.col-lg-7 */}
              </div>
            );
          })}

          {/* here new */}

          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default ServiceV5;
