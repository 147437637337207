import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Contact extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <section className="contact-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-two__content">
                  <div className="contact-two__block">
                    <h3>Don't Hesitate To Contact Us</h3>
                    <p>
                      Don't hesitate to contact us for any assistance or
                      inquiries; our team is ready to support you every step of
                      the way.
                    </p>
                  </div>
                  {/* /.contact-two__block */}
                  <ul className="list-unstyled contact-two__info-list">
                    <li>
                      <div className="contact-two__info-list-title">
                        <i className="far fa-phone" />
                        Call Us :
                      </div>
                      {/* /.contact-two__info-list-title */}
                      <p>
                        <a href="tel:08964712365">+1 6472480518</a>
                      </p>
                    </li>
                    <li>
                      <div className="contact-two__info-list-title">
                        <i className="far fa-envelope" />
                        Email Us :
                      </div>
                      {/* /.contact-two__info-list-title */}
                      <p>
                        <a href="mailto:support@gmail.com">support@gmail.com</a>
                      </p>
                    </li>
                  </ul>
                  {/* /.contact-two__info-list */}
                </div>
                {/* /.contact-two__content */}
              </div>
              {/* /.col-lg-5 */}
              <div className="col-lg-7">
                <form action="#" className="contact-two__form">
                  <div className="contact-two__block">
                    <h3>Get In Touch</h3>
                   
                  </div>
                  {/* /.contact-two__block */}
                  <div className="row">
                    <div className="col-md-6">
                      <input type="text" placeholder="Full Name" />
                    </div>
                    {/* /.col-md-6 */}
                    <div className="col-md-6">
                      <input type="text" placeholder="Email Address" />
                    </div>
                    {/* /.col-md-6 */}
                    <div className="col-md-12">
                      <input type="text" placeholder="Subject" />
                    </div>
                    {/* /.col-md-12 */}
                    <div className="col-md-12">
                      <textarea
                        name="message"
                        placeholder="Message"
                        defaultValue={""}
                      />
                      <button
                        type="submit"
                        className="thm-btn contact-two__btn"
                      >
                        Send Message <i className="fa fa-angle-double-right" />
                      </button>
                      {/* /.thm-btn contact-two__btn */}
                    </div>
                    {/* /.col-md-12 */}
                  </div>
                  {/* /.row */}
                </form>
                {/* /.contact-two__form */}
              </div>
              {/* /.col-lg-7 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /.contact-two */}
      
      </div>
    );
  }
}

export default Contact;
