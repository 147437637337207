import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutStyleTwo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="about-two  go-top">
        <img
          src={publicUrl + "assets/images/shapes/about-two-bg.png"}
          alt={imagealt}
          className="about-two__bg"
        />
        <div className="container">
          <img
            src={publicUrl + "assets/images/resources/cta-2-1.png"}
            alt={imagealt}
            className="about-two__moc"
          />
          <div className="row">
            <div className="col-lg-6">
              <div className="about-two__content">
                <div className="block-title text-left">
                  <p>
                    <span>What We Do</span>
                  </p>
                  <h3>
                    We Offer Best Solution <br /> For Your Business
                  </h3>
                  <div className="block-title__line" />
                  {/* /.block-title__line */}
                </div>
                {/* /.block-title */}
                <p>
                  We provide the best solutions for your business needs,
                  leveraging our expertise to deliver tailored strategies and
                  services that drive growth and success.
                </p>
                <div className="about-two__box">
                  <div className="about-two__box-icon">
                    <i className="far fa-leaf" />
                  </div>
                  {/* /.about-two__box-icon */}
                  <div className="about-two__box-content">
                    <h3>Manage Your Project</h3>
                    <p>
                      Let us manage your project efficiently, utilizing our
                      expertise to ensure seamless coordination, timely
                      delivery, and successful execution from start to finish.
                    </p>
                  </div>
                  {/* /.about-two__box-content */}
                </div>
                {/* /.about-two__box */}
                <div className="about-two__box">
                  <div className="about-two__box-icon">
                    <i className="far fa-laptop-code" />
                  </div>
                  {/* /.about-two__box-icon */}
                  <div className="about-two__box-content">
                    <h3>Business &amp; Data Analytics</h3>
                    <p>
                      Harnessing the power of business and data analytics, we
                      provide invaluable insights to optimize your operations,
                      enhance decision-making, and drive sustainable growth in
                      today's competitive landscape.
                    </p>
                  </div>
                  {/* /.about-two__box-content */}
                </div>
                {/* /.about-two__box */}
              </div>
              {/* /.about-two__content */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default AboutStyleTwo;
